.gen2021banner-rainbow-animation {
    background: black;
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    animation: shopscene-rainbow-animation 15s ease-in-out infinite;
    background-size: 400% 100%;
}

@keyframes gen2021banner-rainbow-animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}