body {
  font-family: 'Cabin', sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #212121;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a.hoverUnderline {
  color: #2979FF;
}

a.hoverUnderline:hover {
  text-decoration: underline;
}

.hoverUnderlineBorder {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.hoverUnderlineBorder:hover {
  border-bottom: 1px solid #000;
}

a.hoverUnderlinePreserveColor {
  text-decoration: none;
}

a.hoverUnderlinePreserveColor:hover {
  text-decoration: underline;
}

a.hoverBold {

}

a.hoverBold:hover {
  font-weight: bold;
}

.rainbowBackground {
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

@-webkit-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}

footer a {
  color: #757575;
  line-height: 24px;
}

footer a:hover {
  color: #000;
  font-weight: 500;
}

footer a.redditLink {
  color: #FF5700;
}

footer a.redditLink:hover {
  color: #FF5700;
}

footer a.discordLink {
  color: #2C2F33;
}

footer a.discordLink:hover {
  color: #2C2F33;
}

footer a.twitterLink {
  color: #0084b4;
}

footer a.twitterLink:hover {
  color: #0084b4;
}

footer a.blogLink {
  color: #3CB371;
}

footer a.blogLink:hover {
  color: #3CB371;
}

footer a.footerEmailLink {
  border-bottom: 2px solid #50D0FE;
}

footer a.footerEmailLink:hover {
  color: #50D0FE;
}
