.rarebits-container {
    /** This is all just styling to make the font look nice, can safely remove */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

    font-family: "Lato", "Gibson", "Helvetica", sans-serif;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/** Button Styles **/
.rarebits-button {
    padding: 8px 16px;
    border-radius: 2px;
    min-width: 100px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    background-color: #3453B1;
    color: white;
    border: 0;
}

.rarebits-button:active, .rarebits-button:focus {outline: none;}
.rarebits-button:active {opacity: 0.5;}
.rarebits-button:hover {background-color: #303f9f; cursor: pointer;}
.rarebits-button:disabled {background-color: #ccc !important; cursor: inherit !important;}

.rarebits-success {background-color: #27ae60;}
.rarebits-success:hover {background-color: #2abc68;}

.rarebits-danger {background-color: rgb(217, 83, 79);}
.rarebits-danger:hover {background-color: #d83234;}

/** Message style, displays below button */
.rarebits-message {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
}

.rarebits-message-error {color: #e74c3c;}
.rarebits-message-info {color: #3498db;}