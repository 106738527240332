.shopscene-mint-button {
    background-color: #23cc01;
}

.shopscene-mint-button:hover {
    opacity: 0.92;
}

.shopscene-mint-button:active {
    opacity: 0.8;
}

.shopscene-track-button {
    background-color: blue;
}

.shopscene-track-button:hover {
    opacity: 0.92;
}

.shopscene-track-button:active {
    opacity: 0.8;
}

.shopscene-rainbow-text-animated {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shopscene-rainbow-animation 8s ease-in-out infinite;
    background-size: 400% 100%;
}

.shopscene-rainbow-background-animated {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    animation: shopscene-rainbow-animation 8s ease-in-out infinite;
    background-size: 400% 100%;
}

@keyframes shopscene-rainbow-animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}